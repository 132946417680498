<template>
  <Page :classes="['page--vcenter']">

    <form class="auth" v-if="!isDone" v-on:submit.prevent="sendResetPasswordRequest">
      <div class="auth__header">
        <h1 class="auth__title">{{$t('auth.recover_password')}}</h1>
      </div>

      <InputPassword
        name="password"
        :label="$t('common.new_password')"
        v-model.trim="password"
        :isRequired="true">
      </InputPassword>

      <InputPassword
        name="password_repeat"
        :label="$t('auth.password_repeat')"
        v-model.trim="password2"
        :isRequired="true">
        <span v-if="passwordsDontMatch">
          {{$t('auth.passwords_dont_match')}}
        </span>
      </InputPassword>

      <Button
        :isLoading="isLoading"
        :isDisabled="isInvalid"
        :classes="['btn--accent', 'btn--wide']"
        @onclick="sendResetPasswordRequest()">
          <span>{{$t('auth.recover_password')}}</span>
      </Button>

      <p class="auth__link">
        <router-link :to="{name: 'login', params: { locale: $i18n.locale }}">
          {{$t('common.back_to_home')}}
        </router-link>
      </p>
    </form>

    <div v-if="isDone" class="auth">
      <div class="auth__header">
        <h1 class="auth__title">{{$t('auth.password_changed')}}</h1>
      </div>
      <p class="auth__tip">{{$t('auth.password_changed_tip')}}</p>

      <p class="auth__link">
        <router-link :to="{name: 'login', params: { locale: $i18n.locale }}">
          {{$t('common.back_to_home')}}
        </router-link>
      </p>
    </div>

  </Page>
</template>

<script>
import Check from '@/services/check/Check';
import InputPassword from '@/components/inputs/password/InputPassword.vue';

export default {
  name: 'ResetPassword',
  components: {
    InputPassword,
  },
  data() {
    return {
      password: '',
      password2: '',
      isLoading: false,
      isDone: false,
    };
  },
  computed: {
    hasQueryParams() {
      return this.$route.query && this.$route.query.uidb64 && this.$route.query.token;
    },
    isInvalid() {
      return !Check.isValidPassword(this.password)
        || this.password !== this.password2;
    },
    passwordsDontMatch() {
      return Check.isValidPassword(this.password2)
        && this.password !== this.password2;
    },
  },
  methods: {
    sendResetPasswordRequest() {
      this.isLoading = true;

      this.$store.dispatch('resetPassword', {
        password: this.password2,
        uidb: this.$route.query.uidb64,
        token: this.$route.query.token,
      }).then(() => {
        this.isDone = true;
      }).finally(() => {
        this.isLoading = false;
      });
    },
  },
  created() {
    if (!this.hasQueryParams) {
      this.$router.push({ name: 'login', params: { locale: this.$i18n.locale } });
    }
  },
};
</script>
